/** @jsx jsx */
import { AspectRatio, jsx, Text } from "theme-ui";
import React from "react";
import PageSection from "./PageSection";
import CompaniesGrid from "./CompaniesGrid";
import YouTube from "react-youtube";

const TrackRecordSection = (props) => (
  <PageSection
    id="track-record"
    bg="light"
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      paddingY: "xl",
    }}
  >
    <h1 sx={{ fontSize: 5 }}>Harmon Brothers: A Home Run Machine</h1>
    {/*<AspectRatio*/}
    {/*  ratio={1.75}*/}
    {/*  sx={{ maxWidth: 1100, width: "100%", backgroundColor: "red" }}*/}
    {/*>*/}
    {/*  <YouTube*/}
    {/*    containerClassName="youtube-player-container"*/}
    {/*    className="youtube-player"*/}
    {/*    videoId="8-_MpCqWhEw"*/}
    {/*  />*/}
    {/*</AspectRatio>*/}
    <Text sx={{ maxWidth: 1100, fontSize: 3 }}>
      Harmon Brothers is the world leader in consumer brand creation through
      viral video campaigns. They have driven more than 1.3B views for their
      clients’ videos. A well performing venture fund needs 10% of it's
      portfolio to return more than 10x (home run). For early stage companies,
      the Harmon Brothers hit a home run 45%* of the time.
    </Text>
    <CompaniesGrid />
    <Text sx={{ maxWidth: 1100, fontSize: 1, marginTop: "large" }}>
      *While we feel that this data is representative of Harmon Brothers impact
      on the companies, Harmon Brothers did not historically invest in their
      clients and does not have direct access to revenue numbers. This data is a
      combination of extrapolations from the e-commerce analytics platform
      Second Measure and anecdotes shared from the companies and their
      investors. FiberFix excluded for lack of data but was acquired
      successfully by J-B Weld.
    </Text>
  </PageSection>
);

export default TrackRecordSection;
