/** @jsx jsx */
import { Image, jsx, Text } from "theme-ui";
import React from "react";
import PageSection from "./PageSection";
import logoLight from "../images/logo-light.png";

const HeroSection = (props) => (
  <PageSection
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "60vh",
    }}
  >
    <Image
      src={logoLight}
      sx={{ minHeight: 100, maxWidth: "100%", maxHeight: 150, width: "auto" }}
    />
    <Text sx={{ fontSize: 3, marginTop: "large", textAlign: "center" }}>
      Building the world's next major consumer brand
    </Text>
  </PageSection>
);

export default HeroSection;
