/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link } from "gatsby";

const CustomLink = (props) => {
  const { style, children, to } = props;
  return (
    <Link
      to={to}
      sx={{
        color: "textOnDark",
        ...style,
        textDecoration: "none",
        ":visited, :active": { color: "textOnDark" },
        ":hover": {
          color: "#B8B8B8",
        },
      }}
    >
      {props.children}
    </Link>
  );
};

export default CustomLink;
