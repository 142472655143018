/** @jsx jsx */
import { Grid, jsx } from "theme-ui";
import React from "react";
import Company from "./Company";

const companies = [
  {
    imageUrl:
      "https://cdn.shopify.com/s/files/1/1020/1629/files/checkout_logo_35.png?height=628&pad_color=ffffff&v=1517006495&width=1200",
    companyName: "Poo Pourri",
    startTime: "Sep 2013",
    tagLine: "Before you go toilet spray",
    revenueStart: "$7M",
    revenueEnd: "$76.8M",
    growthFactor: "11x",
    hitCategory: "homerun",
  },
  {
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/en/f/fb/VidAngel_logo.png",
    companyName: "VidAngel",
    startTime: "Jan 2014",
    tagLine: "Video streaming platform filter",
    revenueStart: "$100K",
    revenueEnd: "$45M",
    growthFactor: "450x",
    hitCategory: "homerun",
  },
  {
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/e/ee/Logo_of_M-DISC.svg",
    companyName: "M-Disc",
    startTime: "Jul 2015",
    tagLine: "Archival data storage solution",
    revenueStart: "$250K",
    revenueEnd: "$0",
    growthFactor: null,
    hitCategory: "strikeout",
  },
  {
    imageUrl:
      "https://cdn.shopify.com/s/files/1/0260/7366/1474/files/SquattyPottyLogo.jpg?height=628&pad_color=ffffff&v=1571336033&width=1200",
    companyName: "Squatty Potty",
    startTime: "Oct 2015",
    tagLine: "A toilet stool that helps you poop better",
    revenueStart: "$7M",
    revenueEnd: "$30M",
    growthFactor: "4x",
    hitCategory: "single",
  },
  {
    imageUrl:
      "https://res.cloudinary.com/dspvkz45d/image/upload/f_auto,q_auto/v1531586391/purple-logo_dunv7v",
    companyName: "Purple",
    startTime: "Nov 2015",
    tagLine: "Innovative gel grid mattresses",
    revenueStart: "$3M",
    revenueEnd: "$428M",
    growthFactor: "143x",
    hitCategory: "homerun",
  },
  {
    imageUrl:
      "https://lever-client-logos.s3.amazonaws.com/e0d8d6bc-3171-48fa-8c2e-be50fd9d1659-1562186706031.png",
    companyName: "Chatbooks",
    startTime: "Jun 2016",
    tagLine: "Beyond easy photo books",
    revenueStart: "$9M",
    revenueEnd: "$42M",
    growthFactor: "5x",
    hitCategory: "single",
  },
  {
    imageUrl:
      "https://surveymonkey-assets.s3.amazonaws.com/survey/166014996/5653fce1-b06a-4ea1-b13b-15b441e3737f.jpg",
    companyName: "Camp Chef",
    startTime: "May 2017",
    tagLine: "The way to cook outdoors",
    revenueStart: "$1M",
    revenueEnd: "$14M",
    growthFactor: "14x",
    hitCategory: "homerun",
  },
  {
    imageUrl:
      "https://cdn.shopify.com/s/files/1/0750/6693/files/Logo_190x@2x.png?v=1529196185",
    companyName: "Bed Jet",
    startTime: "Jun 2018",
    tagLine: "Climate comfort sleep technology just for beds",
    revenueStart: "$3.8M",
    revenueEnd: "$7.6M",
    growthFactor: "2x",
    hitCategory: "single",
  },
  {
    imageUrl:
      "https://cdn.shopify.com/s/files/1/1705/6325/files/Cubcoats_Grey_Transparent_Logo_XL.png?height=628&pad_color=fff&v=1588847561&width=1200",
    companyName: "Cub Coats",
    startTime: "Oct 2018",
    tagLine: "Stuffed animal that transforms into a hoodie",
    revenueStart: "$3M",
    revenueEnd: "$8M",
    growthFactor: "3x",
    hitCategory: "single",
  },
  {
    imageUrl:
      "https://cdn.shopify.com/s/files/1/0172/9173/9236/files/LumeLogo-1200px_2x_c7c4636d-9b17-4106-8c02-3b00bc8cb279.png?&pad_color=ffffff&v=1547472446&width=1200",
    companyName: "Lume",
    startTime: "Dec 2018",
    tagLine: "The world's best natural deodorant",
    revenueStart: "$1.5M",
    revenueEnd: "$50M",
    growthFactor: "33x",
    hitCategory: "homerun",
  },
  {
    imageUrl:
      "https://cdn.shopify.com/s/files/1/0015/0572/2417/files/TB-horizontal-logo_600x@2x.jpg?v=1559351279",
    companyName: "Taos",
    startTime: "Jul 2019",
    tagLine: "Healthy, organic snack bars",
    revenueStart: "$300K",
    revenueEnd: "$300K",
    growthFactor: "1x",
    hitCategory: "strikeout",
  },
];

export default function CompaniesGrid(props) {
  return (
    <Grid
      sx={{
        gridTemplateColumns: ["1fr", "1fr 1fr 1fr"],
        gridGap: "large",
        marginTop: "large",
      }}
    >
      {companies.map((company) => (
        <Company {...company} />
      ))}
    </Grid>
  );
}
