import React from "react";
import HeroSection from "../components/HeroSection";
import TrackRecordSection from "../components/TrackRecordSection";
import TeamSection from "../components/TeamSection";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "../index.css";
import { Helmet } from "react-helmet";

const IndexPage = () => {
  return (
    <main>
      <Helmet>
        <title>Ad Ventures</title>
      </Helmet>
      {/*<Header />*/}
      <HeroSection />
      <TrackRecordSection />
      {/*<TeamSection />*/}
      <Footer />
    </main>
  );
};

export default IndexPage;
