/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";

const PageSection = (props) => {
  const { bg, fullWidth = false, style, id, children } = props;
  return (
    <section
      id={id}
      sx={{
        backgroundColor: bg === "light" ? "lightBg" : "darkBg",
        minHeight: "80vh",
        paddingX: fullWidth ? "none" : ["large", "xxl"],
        color: bg === "light" ? "textOnLight" : "textOnDark",
        ...style,
      }}
    >
      {children}
    </section>
  );
};

export default PageSection;
