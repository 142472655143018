/** @jsx jsx */
import { Flex, Image, jsx } from "theme-ui";
import React from "react";

const crossedBatsImageUrl =
  "https://www.meetmtp.com/wp-content/uploads/2018/05/Baseball-Bat-Transparent-PNG.png";

const mojiMap = {
  homerun: (
    <Flex variant="flex.emojiContainer">
      <Image
        src={crossedBatsImageUrl}
        sx={{ height: "crossedBatsImage", marginRight: "small" }}
      />{" "}
      HOMERUN
    </Flex>
  ),
  single: <Flex variant="flex.emojiContainer">⚾️ SINGLE</Flex>,
  strikeout: <Flex variant="flex.emojiContainer">❌ STRIKEOUT</Flex>,
};

export default function Company(props) {
  const {
    imageUrl,
    revenueStart,
    revenueEnd,
    startTime,
    companyName,
    tagLine,
    growthFactor,
    hitCategory,
  } = props;
  return (
    <Flex sx={{ flexDirection: "column", alignItems: "flex-start" }}>
      <Image
        src={imageUrl}
        alt={`${companyName} logo`}
        sx={{ height: "companyImage", width: "auto" }}
      />
      <h3 sx={{ margin: 0 }}>
        {companyName} ({startTime})
      </h3>
      <p sx={{ marginTop: "small" }}>{tagLine}</p>
      <p sx={{ margin: 0 }}>
        <b>
          Revenue Growth:
          <br />
        </b>
        {revenueStart} → {revenueEnd}
        {growthFactor ? <b> ({growthFactor})</b> : ""}
        {mojiMap[hitCategory]}
      </p>
    </Flex>
  );
}
