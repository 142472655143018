/** @jsx jsx */
import { Flex, jsx, Text } from "theme-ui";
import React from "react";
import CustomLink from "./CustomLink";

const Footer = (props) => (
  <footer sx={{ width: "100vw" }}>
    <Flex
      sx={{
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingY: "large",
        paddingX: "xl",
        backgroundColor: "darkBg",
        color: "textOnDark",
      }}
    >
      <Text>Copyright © 2020 Ad Ventures</Text>
      <CustomLink to="mailto:deals@adv.fund">deals@adv.fund</CustomLink>
    </Flex>
  </footer>
);

export default Footer;
